import { Anchor, type _Navigation } from ":shared/components/route";
import { default as routes } from "../routes";
import IdleIcon from "../assets/icons/users-idle.svg";
import ActiveIcon from "../assets/icons/users-active.svg";
import { global } from ":global";
import { LockedAnchor } from ":src/components/overlays";
export default function Schedule(props: _Navigation.SideNavJSXItemProps) {
  const $settings = global.store.hooks.platform.useSettings("evaluation");
  return (
    <Anchor
      class={`relative  ${props?.anchor?.class}`}
      setup={{
        to: routes,
        classes: {
          active: `${props.anchor.setup.classes.active}`,
        },
      }}
      events={{
        getShouldNavigate: () => !$settings().locked,
      }}
      triggers={{
        onBeforeNavigate: () => {
          if ($settings().locked) {
            global.store.actions.alert.pushToast({
              type: "error",
              message: "This page is locked by admin, you can't access it right now!",
            });
          }
        },
      }}
      components={{
        title: "Evaluation",
        idleIcon: <IdleIcon />,
        activeIcon: <ActiveIcon />,
      }}
    >
      <LockedAnchor locked={$settings().locked} />
    </Anchor>
  );
}
